import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
  data: null,
  loading: false,
  error: null,
  showImageBox: false,
  uploadedImage: [],
};

const application = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplicationLoading(state, action) {
      state.loading = action.payload;
    },
    setApplicationField(state, action) {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }

      if (action.payload.uploadedImage) {
        toast.success("Image uploaded successfully");
        console.log(state.uploadedImage);
      }
    },
    addImage(state, action) {
      if (action.payload) {
        state.uploadedImage = [...(state.uploadedImage || []), action.payload];
      }
    },
    removeImage(state, action) {
      // remove by index
      state.uploadedImage = state.uploadedImage.filter(
        (image, index) => index !== action.payload
      );
    }
  },
});

export const { setApplicationLoading, setApplicationField, addImage, removeImage } =
  application.actions;
export default application.reducer;
