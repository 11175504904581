import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Checkbox } from "@mui/material";

import "./Table.css";
import { fetchMapMarkersSuccess } from "../../redux/reducers/mapMarkers";
import { useAuth0 } from "@auth0/auth0-react";
import { selectZones } from "../../redux/reducers/zones";
import { selectSiteStatus } from "../../redux/reducers/siteStatus";
import {
  fetchPathsSuccess,
  selectPathStatus,
} from "../../redux/reducers/paths";
import Translate from "../Translate/Translate";
import helperFunctions from "../../utils/helper";

const TablePath = () => {
  const tableData = useSelector((state) => state.paths.data); // Assuming 'tableData' is stored in the Redux store
  const paths = useSelector((state) => state.paths); // Assuming 'tableData' is stored in the Redux store
  const dispatch = useDispatch();

  const [statusDropdownVisibility, setStatusDropdownVisibility] =
    useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const updateTSPStatusAPI = async (
    tspID,
    newStatus,
    callback
  ) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/update_tsp_status?tsp_id=${tspID}&new_status=${newStatus}`;
    const authToken = accessToken; // Replace with your actual authorization token

    console.log(url);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const handleStatusChange = (event, index) => {
    const new_status = event.target.value;

    const updatedTableData = [...tableData];
    updatedTableData[index] = {
      ...updatedTableData[index],
      path_status: new_status,
    };

    updateTSPStatusAPI(
      updatedTableData[index].path_uuid,
      new_status,
      (resp) => {
        console.log(
          "resp recevied after update status API = ",
          resp,
          updatedTableData
        );
        dispatch(fetchPathsSuccess(updatedTableData)); // Dispatch action to update tableData in Redux store
      }
    );
  };

  const columns = [
    {
      width: 20,
      label: "Path ID",
      dataKey: "path_uuid",
      type: "text",
    },
    {
      width: 12,
      label: "Sites in Path",
      dataKey: "num_sites_in_path",
      type: "text",
    },
    {
      width: 12,
      label: "Resources",
      dataKey: "num_workers",
      type: "text",
    },
    {
      width: 20,
      label: "Path Created",
      dataKey: "path_creation_time",
      type: "date",
    },
    {
      width: 10,
      label: "View Path",
      dataKey: "path_sheet_link",
      type: "link",
    },
    {
      width: 20,
      label: "Status",
      dataKey: "path_status",
      type: "select",
    },
  ];

  const filterConfig = [
    {
      name: "STATUS",
      type: "status",
    },
  ];

  const handleDropdownClick = (configType) => {
    if (configType === "status") {
      setStatusDropdownVisibility(!statusDropdownVisibility);
    }
  };

  const handleStatusCheckboxClick = (checked, statusType) => {
    const statusSelectObj = {};
    statusSelectObj[statusType] = !checked;

    dispatch(selectPathStatus(statusSelectObj));
  };

  const fetchPaths = async (callback) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    // check for local storage
    const cachedPaths = localStorage.getItem("paths");
    if (cachedPaths) {
      const parsedCachedPaths = JSON.parse(cachedPaths);
      if (parsedCachedPaths && parsedCachedPaths.length > 0) {
        dispatch(fetchPathsSuccess(parsedCachedPaths));
      }
    }

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/get_tsp`;
    const authToken = accessToken; // Replace with your actual authorization token

    console.log(url);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (!data) {
          return;
        }

        // set local storage
        localStorage.setItem("paths", JSON.stringify(data));

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchPaths((data) => {
      dispatch(fetchPathsSuccess(data));
    });
  }, []);

  return (
    <div className="table-container">
      <div className="filters-section">
        <div className="filters-left-section">
          {filterConfig.map((config) => (
            <div className="filters-button-container">
              <h5 className="filters-dropdown-label">
                {<Translate text={config.name} />}
              </h5>
              <div className="filters-dropdown-sub-container">
                <div
                  className="filters-dropdown-selected-label"
                  onClick={() => handleDropdownClick(config.type)}
                >
                  <p>
                    <Translate text={"Selected"} />
                  </p>

                  <Icon
                    baseClassName={"fas"}
                    className={"cursor-pointer fa-caret-down"}
                    fontSize="small"
                  />
                </div>

                <div>
                  <div
                    className={
                      "status-select-dropdown" +
                      (config.type === "status" && statusDropdownVisibility
                        ? ""
                        : " hide")
                    }
                  >
                    {config.type === "status" &&
                      paths.statusOptions.map((statusObj) => {
                        return (
                          <div
                            key={statusObj.value}
                            className="site-info-status-select-sub-container cursor-pointer"
                            style={{ color: statusObj.color }}
                            onClick={() => {
                              handleStatusCheckboxClick(
                                paths.selectedStatusOptions[statusObj.value],
                                statusObj.value
                              );
                            }}
                          >
                            <Checkbox
                              onChange={(evt) =>
                                handleStatusCheckboxClick(
                                  paths.selectedStatusOptions[statusObj.value],
                                  statusObj.value
                                )
                              }
                              checked={
                                paths.selectedStatusOptions[statusObj.value] ===
                                true
                              }
                            />

                            <Icon
                              baseClassName={statusObj.iconBaseClass || "fas"}
                              className={"cursor-pointer " + statusObj.icon}
                              fontSize="small"
                            />

                            <p className="m-0" style={{ flexGrow: 1 }}>
                              {<Translate text={statusObj.label} />}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="filters-right-section">
          <h2>
            <Translate text={"Saved Paths"} />
          </h2>
        </div>
      </div>
      <div className="table-sub-container">
        <div className="row table-header">
          {columns.map((val) => (
            <div
              style={{
                width: val.width + "%",
              }}
            >
              <h4>
                <Translate text={val.label} />
              </h4>
            </div>
          ))}
        </div>

        <div className="data-row-container">
          {tableData.map((data, index) => {
            if (paths.selectedStatusOptions[data.path_status] === true) {
              return (
                <div className="row data-row">
                  {columns.map((val) =>
                    val.type === "select" ? (
                      <select
                        value={data[val.dataKey]}
                        onChange={(event) => handleStatusChange(event, index)}
                        title="Change Status"
                        aria-label="Change Status"
                      >
                        {paths.statusOptions.map((status) => (
                          <option value={status.value}>
                            <Translate text={status.label} />
                          </option>
                        ))}
                      </select>
                    ) : val.type === "link" ? (
                      <div
                        className="m-0"
                        style={{
                          width: val.width + "%",
                        }}
                        title="View Path"
                        onClick={() => window.open(data[val.dataKey], "_blank")}
                      >
                        <Icon
                          baseClassName="fas"
                          style={{
                            width: val.width + "%",
                          }}
                          className="fa-regular fa-link cursor-pointer"
                          fontSize="medium"
                        />
                      </div>
                    ) : val.type === "date" ? (
                      <p
                        className="m-0"
                        style={{
                          width: val.width + "%",
                        }}
                        title={data[val.dataKey]}
                      >
                        <Translate text={helperFunctions.formatDate(data[val.dataKey])} />
                      </p>
                    ) : (
                      <p
                        className="m-0"
                        style={{
                          width: val.width + "%",
                        }}
                        title={val.label}
                      >
                        <Translate text={data[val.dataKey]} />
                      </p>
                    )
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default TablePath;
