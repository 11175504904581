import React, { useState, useEffect } from "react";
import { Icon, Button, FormControlLabel, Switch } from "@mui/material";

import "./Sidebar.css";
import logoThumbLight from "../../assets/images/logo-thumb-bg.png";
import logoHorizontalLight from "../../assets/images/logo-horizontal-bg.png";
import logoThumbDark from "../../assets/images/logo-thumb.png";
import logoHorizontalDark from "../../assets/images/logo-horizontal.png";

import { useDispatch, useSelector } from "react-redux";
import { setSidebarVisibility } from "../../redux/reducers/selectedSitesSidebar";
import { useAuth0 } from "@auth0/auth0-react";

import { selectLanguage } from "../../redux/reducers/user";
import Translate from "../Translate/Translate";

import { useTheme } from "../../ThemeContext";

function Sidebar(props) {
  const showTable = (viewSection) => {
    console.log("sidebar click - get table");
    props.handleSidebarClick(viewSection);
  };

  const { logout } = useAuth0();

  const { theme, toggleTheme } = useTheme();

  const user = useSelector((state) => state.user);

  const handleLanguageChange = (event) => {
    console.log("language change");
    console.log(event.target.checked);
    if (!event.target.checked) {
      dispatch(selectLanguage("hi"));
    } else {
      dispatch(selectLanguage("en"));
    }
  };

  const handleThemeChange = (event) => {
    console.log("theme change");
    console.log(event.target.checked);
    toggleTheme();
  };

  const dispatch = useDispatch();

  return (
    <div className={`sidebar-container ${theme}`}>
      <div className="sidebar-sub-container sidebar-sub-container-top">
        <div
          className="sidebar-row-container sidebar-row-container-logo"
          onClick={() => showTable("map")}
        >
          <img
            src={theme === "light" ? logoThumbLight : logoThumbDark}
            alt="logo"
            className="sidebar-logo sidebar-container-row collapsed-logo"
          />

          <img
            src={theme === "light" ? logoHorizontalLight : logoHorizontalDark}
            alt="logoHorizontal"
            className="sidebar-logo sidebar-container-row full-width-logo"
          />
        </div>

        <div className="sidebar-row-container" onClick={() => showTable("map")}>
          <Icon
            title="Explore"
            baseClassName="fas"
            className="fa-regular fa-location-dot sidebar-container-row"
            fontSize="medium"
          />

          <p>
            <Translate text="Explore" />
          </p>
        </div>

        <div
          className="sidebar-row-container"
          onClick={() => showTable("saved-paths-table")}
        >
          <Icon
            title="Paths"
            baseClassName="fa"
            className="fa-regular fa-bookmark sidebar-container-row"
            fontSize="medium"
          />

          <p>
            <Translate text="Paths" />
          </p>
        </div>

        <div
          className="sidebar-row-container"
          onClick={() => showTable("sites-table")}
        >
          <Icon
            title="Sites"
            baseClassName="fas"
            className="fa-regular fa-table sidebar-container-row"
            fontSize="medium"
          />

          <p>
            <Translate text="Sites" />
          </p>
        </div>

        <div
          className="sidebar-row-container"
          onClick={() => showTable("payment")}
        >
          <Icon
            title="Payment"
            baseClassName="fa"
            color="var(--text-color)"
            className="fa-solid fa-money sidebar-container-row"
            fontSize="medium"
          />

          <p>
            <Translate text="Payment" />
          </p>
        </div>

        <div
          className="sidebar-row-container"
          onClick={() => showTable("dashboard")}
        >
          <Icon
            title="Dashboard"
            baseClassName="fa"
            color="var(--text-color)"
            className="fa-solid fa-chart-simple sidebar-container-row"
            fontSize="medium"
          />

          <p>
            <Translate text="Dashboard" />
          </p>
        </div>
      </div>

      <div className="sidebar-sub-container sidebar-sub-container-bottom">
        <div className="sidebar-row-container">
          <Icon
            title="Language"
            baseClassName="fas"
            className="fa-solid fa-language sidebar-container-row"
            fontSize="medium"
          />
          <p className="cursor-pointer">
            <label htmlFor="languageChange" className="cursor-pointer">
              हिं
            </label>
            <Switch
              id="languageChange"
              defaultChecked={user.language === "en"}
              onChange={handleLanguageChange}
            />
            <label htmlFor="languageChange" className="cursor-pointer">
              En
            </label>
          </p>
        </div>

        <div className="sidebar-row-container">
          <Icon
            title="Theme"
            baseClassName="fas"
            className="fa-solid fa-sun sidebar-container-row"
            fontSize="medium"
          />
          <p className="cursor-pointer">
            <label htmlFor="themeChange" className="cursor-pointer">
              <Translate text="Light" />
            </label>
            <Switch
              id="themeChange"
              defaultChecked={theme === "dark"}
              onChange={handleThemeChange}
            />
            <label htmlFor="themeChange" className="cursor-pointer">
              <Translate text="Dark" />
            </label>
          </p>
        </div>

        <div
          className="sidebar-row-container"
          onClick={() => {
            // remove token from local storage
            localStorage.clear();

            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
        >
          <Icon
            title="Logout"
            baseClassName="fas"
            className="fa-solid fa-right-from-bracket sidebar-container-row"
            fontSize="medium"
          />
          <p>
            <Translate text="Logout" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
