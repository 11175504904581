import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const selectedMarker = createSlice({
  name: "selectedMarker",
  initialState,
  reducers: {
    fetchSelectedMarkerStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSelectedMarkerSuccess(state, action) {
      console.log("fetchDataSuccess selectedMarker success", state.data, action.payload);
      state.loading = false;
      state.data = {
        ...state.data,
        ...action.payload
      }
      console.log("fetchDataSuccess selectedMarker success data", state.data);
    },
    fetchSelectedMarkerFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetSelectedMarker(state) {
      state.data = {};
      state.loading = false;
      state.error = null;
    }
  },
});

export const { fetchSelectedMarkerStart, fetchSelectedMarkerSuccess, fetchSelectedMarkerFailure, resetSelectedMarker } =
  selectedMarker.actions;
export default selectedMarker.reducer;
