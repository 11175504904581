import React, { useEffect, useState } from "react";
import "./UploadImageSidebar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addImage,
  removeImage,
  setApplicationField,
} from "../../redux/reducers/application";
import { Icon } from "@mui/material";
import toast from "react-hot-toast";
import Translate from "../Translate/Translate";
import { useAuth0 } from "@auth0/auth0-react";

const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject("Geolocation is not supported in this browser");
      return;
    }

    const successCallback = (position) => {
      resolve({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    };

    const errorCallback = (error) => {
      reject(error);
    };

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  });
};

const MAX_IMAGE_COUNT = 5;
const MAX_IMAGE_SIZE = 10000000; // 10MB

const UploadImageSidebar = () => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(false);
  const [siteID, setSiteID] = useState(null);
  const uploadedImage = useSelector((state) => state.application.uploadedImage);
  const siteInfo = useSelector(
    (state) => state.verticalSidebar.currentSelectedSite
  );

  const { getAccessTokenSilently } = useAuth0();

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setDragging(false);

    const files = Array.from(e.dataTransfer.files);

    // Perform upload logic here
    handleFiles(files);
  };

  const handleRemoveImage = (index) => {
    dispatch(removeImage(index));
  };

  const handleFiles = (files) => {
    if (files.length === 0) {
      console.log("No files selected");
      toast.error("No files selected");
      return;
    }

    if (
      MAX_IMAGE_COUNT > 0 &&
      files.length +
        (uploadedImage && uploadedImage.length > 0 ? uploadedImage.length : 0) >
        MAX_IMAGE_COUNT
    ) {
      toast.error("Maximum 5 images allowed");
      return;
    }

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (file.type.split("/")[0] !== "image") {
          toast.error("Invalid file type : " + file.type);
          return;
        }

        // Check file size if it exceeds 1MB
        if (file.size > MAX_IMAGE_SIZE) {
          toast.error("File size exceeds 1MB");
          return;
        }

        dispatch(
          addImage({
            src: reader.result,
            name: file.name,
            type: file.type,
            size: file.size,
          })
        );
      };

      reader.onerror = (error) => {
        toast.error("Failed to read file");
      };

      reader.readAsDataURL(file);
    });
  };

  const handleBrowseButtonClick = (e) => {
    // e.preventDefault();
    // e.stopPropagation();
    // Trigger file input click
    document.getElementById("fileInput")?.click();
  };

  const handleFileInputChange = (e) => {
    e.preventDefault();
    // e.stopPropagation();
    console.log("File input change event", e.target.files);
    const files = Array.from(e.target.files);
    handleFiles(files);
  };

  const handleCloseBtnClick = () => {
    dispatch(setApplicationField({ uploadedImage: null, showImageBox: false }));
  };

  const successUploadCallback = () => {
    console.log("Image uploaded successfully");
    handleCloseBtnClick();
    return "Image uploaded successfully";
  };

  const errorUploadCallback = (error) => {
    console.error("Failed to upload images", error);
    return error.message || "Failed to upload images";
  };

  const uploadImage = async (
    siteID,
    latitude,
    longitude,
    imageData,
    accessToken
  ) => {
    if (!siteID) {
      throw new Error("Site ID is required");
    }

    if (!latitude || !longitude) {
      throw new Error("Latitude and Longitude are required");
    }

    if (!imageData) {
      throw new Error("Image data is required");
    }

    if (!accessToken) {
      throw new Error("Access token is required");
    }

    console.log("Upload Image API");
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/upload_ground_inspection_image_dashboard` +
      `?site_id=${siteID}` +
      `&latitude=${latitude}&longitude=${longitude}`;

    // remove data:image/*;base64, from the image data
    imageData = imageData.replace(/^data:image\/[a-z]+;base64,/, "");

    const requestData = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        url: imageData,
      }),
    };

    try {
      const response = await fetch(url, requestData);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Success:", data);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const uploadToServer = async () => {
    try {
      if (!siteID) {
        throw new Error("Site ID is required");
      }

      if (!uploadedImage || uploadedImage.length === 0) {
        throw new Error("No image found to upload");
      }

      const coordinates = await getCoordinates();
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        },
      });

      console.log("Coordinates", coordinates);
      console.debug("Access Token", accessToken);
      console.log("Uploaded Image", uploadedImage);

      // Map each image to a promise of its upload
      const uploadPromises = uploadedImage.map(async (image) => {
        try {
          console.log("Uploading image", image);
          await uploadImage(
            siteID,
            coordinates.latitude,
            coordinates.longitude,
            image.src,
            accessToken
          );
          console.log("Image uploaded successfully", image);
          return { success: true };
        } catch (error) {
          console.error("Error uploading image", error);
          // toast.error(`Failed to upload image.\nName: ${image.name}\nError: ${error.message}`);
          return { success: false, error };
        }
      });

      console.log("Upload Promises", uploadPromises);
      const results = await Promise.allSettled(uploadPromises);
      console.log("Results", results);

      const errors = results.filter((result) => !result?.value?.success);
      if (errors.length > 0) {
        throw new Error("Failed to upload images");
      }

      return "All images uploaded successfully";
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const uploadGroundInspectionImagesAPI = async () => {
    console.log("Upload Ground Inspection Images API ------ ");
    // await uploadToServer();

    toast.promise(uploadToServer(), {
      loading: "Uploading images...",
      success: successUploadCallback,
      error: errorUploadCallback,
    });
  };

  const [totalImages, setTotalImages] = useState(0);

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        handleCloseBtnClick();
      }
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (uploadedImage && uploadedImage.length >= 0) {
      setTotalImages(uploadedImage.length);
    }
  }, [uploadedImage]);

  useEffect(() => {
    if (siteInfo && siteInfo.ID) {
      setSiteID(siteInfo.ID);
    } else {
      setSiteID(null);
      toast.error("Please enter Site ID or choose a site from the map");
    }
  }, [siteInfo]);

  return (
    <div className="upload-image-box-container">
      <div className="header">
        <h4>
          <Translate text={"Upload Ground Inspection Image"} />
        </h4>

        <div
          className="close-btn"
          onClick={() => {
            dispatch(
              setApplicationField({ uploadedImage: null, showImageBox: false })
            );
          }}
        >
          <Icon
            title="Close"
            baseClassName="fa"
            color="var(--text-color)"
            className={"fa-regular fa-circle-xmark cursor-pointer"}
            fontSize="small"
          />
        </div>
      </div>

      <div
        className="middle-box"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="site-info-details-sub-container">
          <div
            style={{ display: "flex", gap: "0.53rem", alignItems: "center" }}
          >
            <h5 className="m-0">
              <Translate text={"Site ID"} />
            </h5>

            <p>
              <Translate text={siteID} />
            </p>
          </div>
        </div>
        
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          style={{ display: "none" }}
        />

        <div
          className={`upload-image-box${dragging ? " dragging" : ""}${
            totalImages > 0 ? " has-images" : " zero-images"
          }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onClick={handleBrowseButtonClick}
        >

          <div className="upload-message">
            <div>
              <Translate text={"Drag and drop image here"} />
            </div>
            
            <button onClick={handleBrowseButtonClick}>
              <Translate text={"Browse from Computer"} />
            </button>

            {/* <button onClick={handleBrowseButtonClick}>
              <Translate text={"Capture New Image"} />
            </button> */}
          </div>
        </div>

        {/* {!uploadedImage ||
          (uploadedImage && !uploadedImage.length && (
            <div className="upload-image-preview">
              <p>No image uploaded</p>
            </div>
          ))} */}

        {uploadedImage && uploadedImage.length > 0 && (
          <div className="upload-image-preview">
            {uploadedImage.map((image, index) => {
              return (
                <div key={index} className="img-box">
                  <img src={image.src} alt="preview" />
                  <div className="row">
                    <p>
                      {image.name.length > 30 ? (
                        <span title={image.name}>
                          {image.name.substring(0, 30) + "..."}
                        </span>
                      ) : (
                        image.name
                      )}
                    </p>
                    <div
                      className="delete-btn"
                      onClick={() => handleRemoveImage(index)}
                    >
                      <Icon
                        title="Remove image"
                        baseClassName="fa"
                        color="var(--text-color)"
                        className={"fa-solid fa-trash"}
                        fontSize="small"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div
        className={
          "footer row" +
          (uploadedImage && uploadedImage.length > 1
            ? " have-images"
            : " zero-images")
        }
      >
        {/* {uploadedImage && uploadedImage.length > 1 && (
          <button className="cursor-pointer upload-more-btn">
            Drag or Add more images
          </button>
        )} */}

        <button
          id="upload-toserver-btn"
          className="cursor-pointer"
          onClick={uploadGroundInspectionImagesAPI}
        >
          <Translate text={"Upload Images"} />
        </button>
      </div>
    </div>
  );
};

export default UploadImageSidebar;
