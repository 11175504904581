import React, { useState } from "react";
import { Icon, TextField } from "@mui/material";

import "./AddModal.css";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import helperFunctions from "../../utils/helper";

function AddModal(props) {
  const { getAccessTokenSilently } = useAuth0();

  const user = useSelector((state) => state.user);

  const fieldConfig = [
    {
      label: "First Name",
      type: "first_name",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Last Name",
      type: "last_name",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Employee ID",
      type: "employee_id",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Phone",
      type: "phone_num",
      width: "48%",
      minLength: 10,
    },
    {
      label: "Zone",
      type: "zone",
      width: "48%",
      minLength: 2,
    },
  ];

  const [formData, setFormData] = useState({});

  const handleEditAddressInput = (inputType, inputValue) => {
    const obj = {};
    obj[inputType] = inputValue;
    setFormData({
      ...formData,
      ...obj,
    });
  };

  const createQueryParamString = async () => {
    const promises = fieldConfig.map(async (config) => {
      if (formData[config.type] && formData[config.type] !== "") {
        const formDataValue = await helperFunctions.translateText(
          formData[config.type],
          "en",
          user.language
        );
        return `${config.type}=${formDataValue}`;
      }
      return ""; // Return an empty string for skipped items
    });

    const resolvedValues = await Promise.all(promises);
    const queryParamString = resolvedValues
      .filter((value) => value !== "")
      .join("&");

    return queryParamString;
  };

  const addFieldMemberAPI = async (callback) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const queryParamString = await createQueryParamString();

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/add_worker?${queryParamString}`;
    const authToken = accessToken; // Replace with your actual authorization token

    console.log(url);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const validateFormData = () => {
    console.log(formData);
    let error = true;

    fieldConfig.map((config) => {
      if (
        formData[config.type] === undefined ||
        (formData[config.type] &&
          formData[config.type].length < config.minLength)
      ) {
        alert(
          `There should be minimum ${config.minLength} characters in ${config.label}`
        );
        error = false;
        return false;
      }
    });

    return error;
  };

  const submitForm = () => {
    if (!validateFormData()) return;

    addFieldMemberAPI((data) => {
      props.setShowModal(false);
    });
  };

  return (
    props.showModal === true && (
      <div className="edit-address-modal-container">
        <div className="edit-address-modal add-modal">
          <div className="edit-address-modal-header">
            <h3>{props.modalHeader || "Modal Header"}</h3>

            <div
              onClick={() => props.setShowModal(false)}
              className="close-modal"
            >
              <Icon
                title="Close"
                baseClassName={"fas"}
                className={"cursor-pointer fa-xmark"}
                fontSize="small"
              />
            </div>
          </div>

          <div className="edit-address-form add-modal-form">
            {fieldConfig.map((config) => {
              return (
                <div
                  className="edit-address-form-cell-container add-modal-cell-container"
                  style={{
                    width: config.width,
                  }}
                >
                  <label htmlFor={config.type}>{config.label}</label>

                  <TextField
                    type="text"
                    className="edit-address-input"
                    name={config.type}
                    id={config.type}
                    fullWidth
                    focused
                    value={
                      formData[config.type] !== null
                        ? formData[config.type]
                        : ""
                    }
                    onInput={(evt) =>
                      handleEditAddressInput(config.type, evt.target.value)
                    }
                  />
                </div>
              );
            })}
          </div>

          <div className="save-address-button">
            <button onClick={() => submitForm()}>Save Address</button>
          </div>
        </div>
      </div>
    )
  );
}

export default AddModal;
