import preloadedTranslations from "../locales/preloadedTranslations.json";

const getDistinctValues = (obj, key) => {
  // Create a Set to store distinct sizes
  const distinctSet = new Set();

  // Loop through the items and add unique sizes to the Set
  obj.forEach((item) => {
    distinctSet.add(item[key]);
  });

  // Convert the Set back to an array
  const distinctValues = Array.from(distinctSet);

  return distinctValues;
};

const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const selectAllValues = (arr) => {
  const obj = {};
  arr.map((val) => {
    obj[val] = true;
  });
  return obj;
};

const translateText = async (sourceText, targetLang, sourceLanguage = "en") => {
  if (sourceLanguage === targetLang) {
    return sourceText;
  }

  // Check localStorage cache first
  const localTranslations =
    JSON.parse(localStorage.getItem("translations")) || {};

  if (
    localTranslations[targetLang] &&
    localTranslations[targetLang][sourceText]
  ) {
    return localTranslations[targetLang][sourceText];
  }

  const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY; // Replace with your actual Google API key
  const baseUrl = "https://translation.googleapis.com/language/translate/v2";

  const params = new URLSearchParams({
    key: apiKey,
    q: sourceText,
    source: sourceLanguage,
    target: targetLang,
  });

  const url = `${baseUrl}?${params}`;

  try {
    const response = await fetch(url);
    const data = await response.json();

    if (data.error) {
      throw new Error(data.error.message);
    }

    const translations = JSON.parse(localStorage.getItem("translations")) || {};
    translations[targetLang] = translations[targetLang] || {};
    translations[targetLang][sourceText] =
      data.data.translations[0].translatedText;

    // Store all translations in local storage
    localStorage.setItem("translations", JSON.stringify(translations));

    return translations[targetLang][sourceText];
  } catch (error) {
    console.error("Translation error:", error);
    return null;
  }
};

const mergeTranslations = () => {
  const translations = JSON.parse(localStorage.getItem("translations")) || {};

  const mergedTranslations = {};

  for (const language in translations) {
    mergedTranslations[language] = {
      ...translations[language],
      ...preloadedTranslations[language],
    };
  }

  for (const language in preloadedTranslations) {
    if (!mergedTranslations[language]) {
      mergedTranslations[language] = {
        ...translations[language],
        ...preloadedTranslations[language],
      };
    }
  }

  localStorage.setItem("translations", JSON.stringify(mergedTranslations));
};

// get site status transactions from api
const getSiteStatusTransactions = async (authToken, callback) => {
  const url =
    process.env.REACT_APP_API_BASE_URL + `/get_site_status_transactions`;

  fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // Process the retrieved data here
      callback(data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the request
      console.error("Error:", error);
    });
};

function formatDate(inputDateString) {
  try {
    // Split the input date string by spaces and dots
    const parts = inputDateString.split(/[\s.]+/);

    // Define an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract date components
    const day = parts[0].padStart(2, "0");
    const month = monthNames[parseInt(parts[1], 10) - 1];
    const year = parts[2];
    const timeParts = parts[3].split(":");
    let hours = parseInt(timeParts[0].padStart(2, "0"));
    const minutes = timeParts[1].padStart(2, "0");
    const seconds = (timeParts[2][0] + timeParts[2][1]).padStart(2, "0");
    const meridian = hours === 12 ? "PM" : hours < 12 ? "AM" : "PM";

    if (hours == 0) {
      hours = 12;
    } else if (hours > 12) {
      hours = hours - 12;
    }

    hours = hours.toString().padStart(2, "0");

    // Format the date in the desired output format
    const formattedDate = `${day} ${month} ${year} ${hours}:${minutes}:${seconds} ${meridian}`;

    return formattedDate;
  } catch (error) {
    console.error("formatDate error", error);
    return inputDateString;
  }
}

const saveReportUsingAPI = async (authToken, data, callback) => {
  const url =
    process.env.REACT_APP_API_BASE_URL +
    `/get_consolodated_report?delivery_number=${data.delivery_number}&zone=${data.zone}&email_id=${data.email_id}`;

  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      // Process the retrieved data here
      callback(data, null);
    })
    .catch((error) => {
      // Handle any errors that occurred during the request
      console.error("Error:", error);
      callback(null, error);
    });
};

function countTrueValues(obj) {
  return Object.values(obj).reduce(
    (count, value) => count + (value === true ? 1 : 0),
    0
  );
}

const helperFunctions = {
  getDistinctValues: getDistinctValues,
  selectAllValues: selectAllValues,
  generateUUID: generateUUID,
  translateText: translateText,
  mergeTranslations: mergeTranslations,
  getSiteStatusTransactions: getSiteStatusTransactions,
  formatDate: formatDate,
  saveReportUsingAPI: saveReportUsingAPI,
  countTrueValues: countTrueValues,
};

export default helperFunctions;
