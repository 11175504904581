import React from "react";

import "./UploadImageBtn.css";
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationField } from "../../../redux/reducers/application";

const UploadImageBtn = () => {
  const application = useSelector((state) => state.application);
  const dispatch = useDispatch();

  const showImageBox = () => {
    dispatch(setApplicationField({ showImageBox: !application.showImageBox }));
  };

  return (
    <div
      className="upload-image-btn-container circle-border"
      onClick={showImageBox}
    >
      <Icon
        title={"Add new ground inspection image"}
        baseClassName="fas"
        className={"fa-plus fa-2x"}
        fontSize="large"
      />
    </div>
  );
};

export default UploadImageBtn;
