import { createSlice } from "@reduxjs/toolkit";

const statusConfig = [
  {
    label: "New",
    value: "new",
    icon: "fa-solid fa-flag",
    iconBaseClass: "fas",
    color: "var(--text-color)",
  },
  {
    label: "New - Assigned",
    value: "New-Assigned",
    icon: "fa-solid fa-user-xmark",
    iconBaseClass: "fas",
    color: "#EC6547",
  },
  {
    label: "Ground Inspection",
    value: "ground-inspection",
    icon: "fa-solid fa-user",
    iconBaseClass: "fas",
    color: "#F46A9B",
  },
  {
    label: "Notice",
    value: "notice",
    icon: "fa-solid fa-file-circle-exclamation",
    iconBaseClass: "fas",
    color: "#EF9B20",
  },
  {
    label: "Sealed",
    value: "sealed",
    icon: "fa-solid fa-lock",
    iconBaseClass: "fas",
    color: "#EDE15B",
  },
  {
    label: "D.O Issued",
    value: "do-issued",
    icon: "fa-solid fa-circle-exclamation",
    iconBaseClass: "fas",
    color: "#BDCF32",
  },
  {
    label: "Demolished",
    value: "demolished",
    icon: "fa-solid fa-hammer",
    iconBaseClass: "fas",
    color: "#86D87F",
  },
  {
    label: "Map Issued",
    value: "map-issued",
    icon: "fa-solid fa-map",
    iconBaseClass: "fas",
    color: "#27AEEF",
  },
  {
    label: "Court Case",
    value: "court-case",
    icon: "fa-solid fa-landmark",
    iconBaseClass: "fas",
    color: "#B33DC6",
  },
  {
    label: "Compounding Map",
    value: "compounding-map",
    icon: "fa-solid fa-indian-rupee-sign",
    iconBaseClass: "fas",
    color: "#D9A8FF",
  },
  {
    label: "Re-Inspection",
    value: "re-inspection",
    icon: "fa-solid fa-share",
    iconBaseClass: "fas",
    color: "#61E2F4",
  },
  {
    label: "OBPAS",
    value: "obpas",
    icon: "fa-solid fa-book-open",
    iconBaseClass: "fas",
    color: "#FF4185",
  },
  {
    label: "Govt. Campus",
    value: "govt-campus",
    icon: "fa-solid fa-landmark-flag",
    iconBaseClass: "fas",
    color: "#D5D5D5",
  },
  {
    label: "N.A.",
    value: "not-applicable",
    icon: "fa-solid fa-circle-minus",
    iconBaseClass: "fas",
    color: "#b273ff",
  },
  {
    label: "PMAY",
    value: "pmay",
    icon: "fa-solid fa-circle-minus",
    iconBaseClass: "fas",
    color: "var(--text-color)",
    type: "image",
    image: "pmay-logo.png",
  },
];

const statusConfigMap = {
  new: {
    label: "New",
    value: "new",
    icon: "fa-solid fa-flag",
    iconBaseClass: "fas",
    color: "var(--text-color)",
  },
  "New-Assigned": {
    label: "New - Assigned",
    value: "New-Assigned",
    icon: "fa-solid fa-user-xmark",
    iconBaseClass: "fas",
    color: "#EC6547",
  },
  "ground-inspection": {
    label: "Ground Inspection",
    value: "ground-inspection",
    icon: "fa-solid fa-user",
    iconBaseClass: "fas",
    color: "#F46A9B",
  },
  notice: {
    label: "Notice",
    value: "notice",
    icon: "fa-solid fa-file-circle-exclamation",
    iconBaseClass: "fas",
    color: "#EF9B20",
  },
  sealed: {
    label: "Sealed",
    value: "sealed",
    icon: "fa-solid fa-lock",
    iconBaseClass: "fas",
    color: "#EDE15B",
  },
  "do-issued": {
    label: "D.O Issued",
    value: "do-issued",
    icon: "fa-solid fa-circle-exclamation",
    iconBaseClass: "fas",
    color: "#BDCF32",
  },
  demolished: {
    label: "Demolished",
    value: "demolished",
    icon: "fa-solid fa-hammer",
    iconBaseClass: "fas",
    color: "#86D87F",
  },
  "map-issued": {
    label: "Map Issued",
    value: "map-issued",
    icon: "fa-solid fa-map",
    iconBaseClass: "fas",
    color: "#27AEEF",
  },
  "court-case": {
    label: "Court Case",
    value: "court-case",
    icon: "fa-solid fa-landmark",
    iconBaseClass: "fas",
    color: "#B33DC6",
  },
  "compounding-map": {
    label: "Compounding Map",
    value: "compounding-map",
    icon: "fa-solid fa-indian-rupee-sign",
    iconBaseClass: "fas",
    color: "#D9A8FF",
  },
  "re-inspection": {
    label: "Re-Inspection",
    value: "re-inspection",
    icon: "fa-solid fa-share",
    iconBaseClass: "fas",
    color: "#61E2F4",
  },
  obpas: {
    label: "OBPAS",
    value: "obpas",
    icon: "fa-solid fa-book-open",
    iconBaseClass: "fas",
    color: "#FF4185",
  },
  "govt-campus": {
    label: "Govt. Campus",
    value: "govt-campus",
    icon: "fa-solid fa-landmark-flag",
    iconBaseClass: "fas",
    color: "#D5D5D5",
  },
  "not-applicable": {
    label: "N.A.",
    value: "not-applicable",
    icon: "fa-solid fa-circle-minus",
    iconBaseClass: "fas",
    color: "#b273ff",
  },
  pmay: {
    label: "PMAY",
    value: "pmay",
    icon: "fa-solid fa-circle-minus",
    iconBaseClass: "fas",
    color: "var(--text-color)",
    type: "image",
    image: "pmay-logo.png",
  },
};

const initialState = {
  data: statusConfig,
  dataMap: statusConfigMap,
  statusDropdownVisibility: false,
  selected: {
    new: true,
    "New-Assigned": true,
    "ground-inspection": true,
    notice: true,
    sealed: true,
    "do-issued": true,
    demolished: true,
    "map-issued": true,
    "court-case": true,
    "compounding-map": true,
    "re-inspection": true,
    obpas: true,
    "govt-campus": true,
    "not-applicable": true,
    pmay: true,
  },
  loading: false,
  error: null,
};

const siteStatus = createSlice({
  name: "siteStatus",
  initialState,
  reducers: {
    fetchSiteStatusStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchSiteStatusSuccess(state, action) {
      console.log("fetchDataSuccess sitestatus success", action.payload);
      state.loading = false;
      state.data = action.payload;
      console.log("fetchDataSuccess sitestatus success data", state.data);
    },
    selectSiteStatus(state, action) {
      console.log(action.payload);

      state.loading = false;
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    setStatusDropdownVisibility(state, action) {
      state.statusDropdownVisibility = action.payload;
    },
    fetchSiteStatusFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSiteStatusStart,
  fetchSiteStatusSuccess,
  selectSiteStatus,
  fetchSiteStatusFailure,
  setStatusDropdownVisibility,
} = siteStatus.actions;
export default siteStatus.reducer;
