import { createSlice } from "@reduxjs/toolkit";

import helperFunctions from "../../utils/helper";

const initialState = {
  data: [],
  dataMap: {},
  sizes: [],
  selectedSizes: {},
  deliveryNumbers: [],
  selectedDeliveryNumbers: {},
  loading: false,
  error: null,
};

const mapMarkers = createSlice({
  name: "mapMarkers",
  initialState,
  reducers: {
    fetchMapMarkersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchMapMarkersSuccess(state, action) {
      console.log("fetchDataSuccess map markers success", action.payload);

      const dataMap = {};
      const payloadData = action.payload;
      const distinctSizes = helperFunctions.getDistinctValues(
        payloadData,
        "size"
      );

      const distinctDeliveryNumbers = helperFunctions.getDistinctValues(
        payloadData,
        "Delivery Number"
      );

      console.log(
        "fetchDataSuccess map markers success distinct delivery numbers",
        distinctDeliveryNumbers
      );

      payloadData.map((val) => {
        dataMap[val.ID] = val;
      });

      state.loading = false;
      state.sizes = distinctSizes;
      state.selectedSizes = helperFunctions.selectAllValues(distinctSizes);
      state.deliveryNumbers = distinctDeliveryNumbers;
      state.selectedDeliveryNumbers = helperFunctions.selectAllValues(
        distinctDeliveryNumbers
      );
      state.data = action.payload;

      state.dataMap = dataMap;

      console.log("fetchDataSuccess map markers success data", state.data);
    },
    selectSiteSize(state, action) {
      state.loading = false;
      state.selectedSizes = {
        ...state.selectedSizes,
        ...action.payload,
      };
    },
    selectDeliveryNumber(state, action) {
      state.loading = false;
      state.selectedDeliveryNumbers = {
        ...state.selectedDeliveryNumbers,
        ...action.payload,
      };
    },
    fetchMapMarkersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateSiteStatus(state, action) {
      const tempData = [];
      state.data.map((val) => {
        const tempVal = val;
        if (val.ID === action.payload.ID) {
          tempVal.status = action.payload.status;
        }
        tempData.push(tempVal);
      });
      state.data = tempData;

      // update data map
      const tempDataMap = {};
      tempData.map((val) => {
        tempDataMap[val.ID] = val;
      });
      state.dataMap = tempDataMap;

      // update in local storage
      localStorage.setItem("data", JSON.stringify(tempData));
    },
    updateSiteInfoAttributeInAllSites(state, action) {
      const tempData = [];
      state.data.map((val) => {
        const tempVal = val;
        if (val.ID === action.payload.ID) {
          tempVal[action.payload.attribute] = action.payload.value;
        }
        tempData.push(tempVal);
      });
      state.data = tempData;

      // update data map
      const tempDataMap = {};
      tempData.map((val) => {
        tempDataMap[val.ID] = val;
      });
      state.dataMap = tempDataMap;

      // update in local storage
      try {
        localStorage.setItem("data", JSON.stringify(tempData));
      } catch (e) {
        console.log("Error updating local storage: ", e);
      }
    }
  },
});

export const {
  fetchMapMarkersStart,
  fetchMapMarkersSuccess,
  fetchMapMarkersFailure,
  selectSiteSize,
  selectDeliveryNumber,
  updateSiteStatus,
  updateSiteInfoAttributeInAllSites,
} = mapMarkers.actions;
export default mapMarkers.reducer;
