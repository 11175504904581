import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Button, Checkbox, TextField } from "@mui/material";

import pmayLogo from "../../assets/images/pmay-logo.png";

import "./Table.css";
import SelectDropdown from "../SidebarPathTraversal/SelectDropdown";
import {
  fetchMapMarkersSuccess,
  selectDeliveryNumber,
  selectSiteSize,
} from "../../redux/reducers/mapMarkers";
import { useAuth0 } from "@auth0/auth0-react";
import { Check } from "@mui/icons-material";
import { selectZones } from "../../redux/reducers/zones";
import { selectSiteStatus } from "../../redux/reducers/siteStatus";
import Translate from "../Translate/Translate";

const TableComponent = () => {
  const tableData = useSelector((state) => state.markerCoordinates.data); // Assuming 'tableData' is stored in the Redux store
  const sizes = useSelector((state) => state.markerCoordinates.sizes); // Assuming 'tableData' is stored in the Redux store
  const selectedSizes = useSelector(
    (state) => state.markerCoordinates.selectedSizes
  ); // Assuming 'tableData' is stored in the Redux store

  const deliveryNumbers = useSelector(
    (state) => state.markerCoordinates.deliveryNumbers
  );
  const selectedDeliveryNumbers = useSelector(
    (state) => state.markerCoordinates.selectedDeliveryNumbers
  );

  const user = useSelector((state) => state.user);

  const siteStatus = useSelector((state) => state.siteStatus); // Assuming 'tableData' is stored in the Redux store
  const zones = useSelector((state) => state.zones); // Assuming 'tableData' is stored in the Redux store
  const dispatch = useDispatch();

  const [zonesDropdownVisibility, setZonesDropdownVisibility] = useState(false);
  const [sizeDropdownVisibility, setSizeDropdownVisibility] = useState(false);
  const [statusDropdownVisibility, setStatusDropdownVisibility] = useState(false);
  const [deliveryNumberDropdownVisibility, setDeliveryNumberDropdownVisibility] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const updateSiteStatusAPI = async (
    siteID,
    newStatus,
    callback
  ) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/update_site_status?site_id=${siteID}&new_status=${newStatus}`;
    const authToken = accessToken; // Replace with your actual authorization token

    console.log(url);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error:", error);
      });
  };

  const handleStatusChange = (event, index) => {
    const new_status = event.target.value;

    const updatedTableData = [...tableData];
    updatedTableData[index] = {
      ...updatedTableData[index],
      status: new_status,
    };

    updateSiteStatusAPI(
      updatedTableData[index].ID,
      new_status,
      (resp) => {
        console.log(
          "resp recevied after update status API = ",
          resp,
          updatedTableData
        );
        dispatch(fetchMapMarkersSuccess(updatedTableData)); // Dispatch action to update tableData in Redux store
      }
    );
  };

  const columns = [
    {
      width: 5,
      label: "Site ID",
      dataKey: "ID",
      dataType: "text",
    },
    {
      width: 8,
      label: "Zone",
      dataKey: "Zone",
      dataType: "text",
    },
    {
      width: 20,
      label: "Size",
      dataKey: "size",
      dataType: "text",
    },
    // {
    //   width: 15,
    //   label: "Owner Name",
    //   dataKey: "owner_name",
    //   dataType: "text",
    // },
    // {
    //   width: 15,
    //   label: "Notice Date",
    //   dataKey: "notice_date",
    //   dataType: "text",
    // },
    // {
    //   width: 15,
    //   label: "Ground Inspection Size",
    //   dataKey: "ground_inspection_size",
    //   dataType: "text",
    // },
    // {
    //   width: 15,
    //   label: "Remarks",
    //   dataKey: "remarks",
    //   dataType: "text",
    // },
    {
      width: 15,
      label: "Region",
      dataKey: "Region",
      dataType: "text",
    },
    {
      width: 15,
      label: "Property Type",
      dataKey: "Land-Property Classification",
      dataType: "text",
    },
    {
      width: 15,
      label: "Assigned Employee",
      dataKey: "Assigned Employee",
      dataType: "text",
    },
    {
      width: 10,
      label: "Street View",
      dataKey: "sv_Link",
      dataType: "icon",
      padding: "1rem",
    },
    {
      width: 10,
      label: "Status",
      dataKey: "status",
      dataType: "dropdown",
    },
  ];

  const filterConfig = [
    {
      name: "SITE ID",
      type: "ID",
      filterType: "search",
    },
    {
      name: "ZONE",
      type: "zone",
    },
    {
      name: "STATUS",
      type: "status",
    },
    {
      name: "SIZE",
      type: "size",
    },
    {
      name: "DELIVERY NUMBER",
      type: "deliveryNumber",
    },
  ];

  const handleDropdownClick = (configType) => {
    if (configType === "zone") {
      setZonesDropdownVisibility(!zonesDropdownVisibility);
      setStatusDropdownVisibility(false);
      setSizeDropdownVisibility(false);
      setDeliveryNumberDropdownVisibility(false);
    } else if (configType === "status") {
      setStatusDropdownVisibility(!statusDropdownVisibility);
      setZonesDropdownVisibility(false);
      setSizeDropdownVisibility(false);
      setDeliveryNumberDropdownVisibility(false);
    } else if (configType === "size") {
      setSizeDropdownVisibility(!sizeDropdownVisibility);
      setZonesDropdownVisibility(false);
      setStatusDropdownVisibility(false);
      setDeliveryNumberDropdownVisibility(false);
    } else if (configType === "deliveryNumber") {
      setDeliveryNumberDropdownVisibility(!deliveryNumberDropdownVisibility);
      setZonesDropdownVisibility(false);
      setStatusDropdownVisibility(false);
      setSizeDropdownVisibility(false);
    }
  };

  const handleZoneClick = (zone) => {
    const tempObj = {};
    tempObj[zone] = true;

    if (zones.selected[zone] === null || zones.selected[zone] === true) {
      tempObj[zone] = false;
    }

    dispatch(selectZones(tempObj));
  };

  const handleStatusCheckboxClick = (checked, statusType) => {
    const statusSelectObj = {};
    statusSelectObj[statusType] = !checked;

    dispatch(selectSiteStatus(statusSelectObj));
  };

  const handleSiteSizeCheckboxClick = (checked, size) => {
    const sizeSelectObj = {};
    sizeSelectObj[size] = !checked;

    dispatch(selectSiteSize(sizeSelectObj));
  };

  const handleDeliveryNumberCheckboxClick = (checked, value) => {
    const tempObj = {};
    tempObj[value] = !checked;

    dispatch(selectDeliveryNumber(tempObj));
  };

  const handleSearchInput = (val) => {
    setSearchInput(val);
  };

  const dropdownRefs = useRef([]);

  const handleCloseDropdownRef = (dropdown, index) => {
    return (e) => {
      // alert("this is dropdown blur --- "+ dropdown+ index)
      if (dropdownRefs.current[index].contains(e.relatedTarget)) {
        return;
      }

      handleDropdownClick(dropdown)
    };
  };

  return (
    <div className="table-container">
      <div className="filters-section">
        <div className="filters-left-section">
          {filterConfig.map((config, index) => (
            <div className="filters-button-container"
            onBlur={handleCloseDropdownRef(config.type, index)}
            ref={(el) => (dropdownRefs.current[index] = el)} // Set a ref to the first dropdown
            tabIndex={80+index}
            >
              <h5 className="filters-dropdown-label">
                <Translate text={config.name} />
              </h5>

              {config.filterType === "search" ? (
                <div className="search-site-id">
                  <TextField
                    id="search-site-id"
                    placeholder={user.language !== "hi" ? "Search by Site ID" : "साइट आईडी से खोजें"}
                    type="search"
                    fullWidth
                    defaultValue=""
                    focused
                    onInput={(evt) => handleSearchInput(evt.target.value)}
                    InputProps={
                      <Icon
                        baseClassName="fas"
                        className={"fa fa-magnifying-glass"}
                        fontSize="small"
                        color="var(--secondary-text-color)"
                        style={{ color: "var(--secondary-text-color)" }}
                      />
                    }
                  />
                </div>
              ) : (
                <div className="filters-dropdown-sub-container">
                  <div
                    className="filters-dropdown-selected-label"
                    onClick={() => handleDropdownClick(config.type)}
                  >
                    <p>
                      <Translate text={"Selected"} />
                    </p>

                    <Icon
                      baseClassName={"fas"}
                      className={"cursor-pointer fa-caret-down"}
                      fontSize="small"
                    />
                  </div>

                  <div>
                    <div
                      className={
                        "status-select-dropdown" +
                        ((config.type === "status" &&
                          statusDropdownVisibility) ||
                        (config.type === "zone" && zonesDropdownVisibility) ||
                        (config.type === "size" && sizeDropdownVisibility) ||
                        (config.type === "deliveryNumber" && deliveryNumberDropdownVisibility)
                          ? ""
                          : " hide") +
                        (config.type === "zone" || config.type === "size" || config.type === "deliveryNumber"
                          ? " zone-filter-dropdown-container"
                          : "")
                      }
                    >
                      {config.type === "status" &&
                        siteStatus.data.map((statusObj) => {
                          return (
                            <div
                              key={statusObj.value}
                              className="site-info-status-select-sub-container cursor-pointer"
                              style={{ color: statusObj.color }}
                              onClick={() => {
                                handleStatusCheckboxClick(
                                  siteStatus.selected[statusObj.value],
                                  statusObj.value
                                );
                              }}
                            >
                              <Checkbox
                                onChange={(evt) =>
                                  handleStatusCheckboxClick(
                                    siteStatus.selected[statusObj.value],
                                    statusObj.value
                                  )
                                }
                                checked={
                                  siteStatus.selected[statusObj.value] === true
                                }
                              />

                              {statusObj.type === "image" ? (
                                <img
                                  src={pmayLogo}
                                  alt={statusObj.label}
                                  style={{
                                    width: "3.5rem",
                                    height: "1.5rem",
                                  }}
                                />
                              ) : (
                                <Icon
                                  baseClassName={
                                    statusObj.iconBaseClass || "fas"
                                  }
                                  className={"cursor-pointer " + statusObj.icon}
                                  fontSize="small"
                                />
                              )}

                              <p className="m-0" style={{ flexGrow: 1 }}>
                                <Translate text={statusObj.label} />
                              </p>
                            </div>
                          );
                        })}

                      {config.type === "zone" &&
                        zones.data.map((zone) => {
                          return (
                            <div
                              className="site-info-status-select-sub-container cursor-pointer"
                              style={{ color: zone.color }}
                              onClick={() => handleZoneClick(zone.Zone)}
                            >
                              <Checkbox
                                onChange={(evt) =>
                                  handleStatusCheckboxClick(
                                    zones.selected[zone.Zone],
                                    zone.Zone
                                  )
                                }
                                checked={zones.selected[zone.Zone] === true}
                              />

                              <p className="m-0" style={{ flexGrow: 1 }}>
                                <Translate text={zone.Zone} />
                              </p>
                            </div>
                          );
                        })}

                      {config.type === "size" &&
                        sizes.map((sizeValue) => {
                          return (
                            <div
                              className="site-info-status-select-sub-container cursor-pointer"
                              // style={{ color: "var(--text-color)" }}
                              onClick={() =>
                                handleSiteSizeCheckboxClick(
                                  selectedSizes[sizeValue],
                                  sizeValue
                                )
                              }
                            >
                              <Checkbox
                                onChange={() =>
                                  handleSiteSizeCheckboxClick(
                                    selectedSizes[sizeValue],
                                    sizeValue
                                  )
                                }
                                checked={selectedSizes[sizeValue] === true}
                              />

                              <p
                                className="m-0"
                                style={{ flexGrow: 1, fontSize: "0.8rem" }}
                              >
                                {/* {sizeValue} */}
                                <Translate text={sizeValue} />
                              </p>
                            </div>
                          );
                        })}

                      {config.type === "deliveryNumber" &&
                        deliveryNumbers.map((deliveryNumber) => {
                          return (
                            <div
                              className="site-info-status-select-sub-container cursor-pointer"
                              onClick={() =>
                                handleDeliveryNumberCheckboxClick(
                                  selectedDeliveryNumbers[deliveryNumber],
                                  deliveryNumber
                                )
                              }
                            >
                              <Checkbox
                                onChange={() =>
                                  handleDeliveryNumberCheckboxClick(
                                    selectedDeliveryNumbers[deliveryNumber],
                                    deliveryNumber
                                  )
                                }
                                checked={
                                  selectedDeliveryNumbers[deliveryNumber] ===
                                  true
                                }
                              />

                              <p
                                className="m-0"
                                style={{ flexGrow: 1, fontSize: "0.8rem" }}
                              >
                                <Translate text={deliveryNumber || "-- Not Delivered --"} />
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="filters-right-section">
          <h2>
            <Translate text={"Saved Sites"} />
          </h2>
        </div>
      </div>

      {/* Table starts from here */}
      <div className="table-sub-container">
        <div className="row table-header">
          {columns.map((val) => (
            <div
              style={{
                width: val.width + "%",
                maxWidth: val.width + "%",
                overflow: "hidden",
              }}
            >
              <h4>
                <Translate text={val.label} />
              </h4>
            </div>
          ))}
        </div>

        <div className="data-row-container">
          {tableData.map((data, index) => {
            if (
              zones.selected[data.Zone] === true &&
              selectedSizes[data.size] === true &&
              siteStatus.selected[data.status] === true &&
              selectedDeliveryNumbers[data["Delivery Number"]] === true &&
              (searchInput === "" ||
                data?.ID.toString()
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()))
            ) {
              return (
                <div className="row data-row">
                  {columns.map((column) =>
                    column.dataType !== "dropdown" ? (
                      column.dataType === "icon" ? (
                        <div
                          className="m-0"
                          style={{
                            width: column.width + "%",
                            justifyContent: "space-around",
                            display: "flex",
                          }}
                          onClick={() =>
                            window.open(
                              data[column.dataKey] || data.maplink,
                              "_blank"
                            )
                          }
                        >
                          <Icon
                            baseClassName="fas"
                            className="fa-regular fa-location-dot cursor-pointer"
                            fontSize="medium"
                          />
                        </div>
                      ) : (
                        <p
                          className="m-0"
                          style={{
                            width: column.width + "%",
                            maxWidth: column.width + "%",
                          }}
                        >
                          <Translate text={data[column.dataKey]} />
                        </p>
                      )
                    ) : (
                      <select
                        value={data[column.dataKey]}
                        onChange={(event) => handleStatusChange(event, index)}
                        className="m-0"
                        style={{
                          width: column.width + "%",
                          maxWidth: column.width + "%",
                        }}
                      >
                        {siteStatus.data.map((status) => (
                          <option value={status.value}>
                            {<Translate text={status.label} />}
                          </option>
                        ))}
                      </select>
                    )
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default TableComponent;
