import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  statusOptions: [
    {
      label: "In Progress",
      value: "Created/In-Progress",
      icon: "fa-solid fa-spinner",
      iconBaseClass: "fas",
      color: "#a08216",
    },
    {
      label: "Completed",
      value: "Completed",
      icon: "fa-sharp fa-solid fa-check",
      iconBaseClass: "fas",
      color: "green",
    },
  ],
  selectedStatusOptions: {
    "Created/In-Progress": true,
    "Completed": true,
  },
  selected: {},
  loading: false,
  error: null,
};

const paths = createSlice({
  name: "paths",
  initialState,
  reducers: {
    fetchPathsStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchPathsSuccess(state, action) {
      console.log("fetchDataSuccess paths success", action.payload);
      state.loading = false;
      state.data = action.payload;
      console.log("fetchDataSuccess paths success data", state.data);
    },
    selectPaths(state, action) {
      state.loading = false;
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    selectPathStatus(state, action) {
      state.loading = false;
      state.selectedStatusOptions = {
        ...state.selectedStatusOptions,
        ...action.payload,
      };
    },
    fetchPathsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchPathsStart,
  fetchPathsSuccess,
  selectPaths,
  selectPathStatus,
  fetchPathsFailure,
} = paths.actions;
export default paths.reducer;
