import React, { useState, useEffect, useRef } from "react";
import { Icon, Box, TextField, Button, Checkbox } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import Translate from "../Translate/Translate";

import "./MapTopbar.css";
import { fetchSiteidSuccess } from "../../redux/reducers/searchSiteid";
import { selectZones } from "../../redux/reducers/zones";

import { selectSiteStatus } from "../../redux/reducers/siteStatus";
import {
  selectDeliveryNumber,
  selectSiteSize,
} from "../../redux/reducers/mapMarkers";

import pmayLogo from "../../assets/images/pmay-logo.png";

function MapTopbar() {
  const zones = useSelector((state) => state.zones);
  const siteStatus = useSelector((state) => state.siteStatus);
  const sizes = useSelector((state) => state.markerCoordinates.sizes);
  const selectedSizes = useSelector(
    (state) => state.markerCoordinates.selectedSizes
  );
  const deliveryNumbers = useSelector(
    (state) => state.markerCoordinates.deliveryNumbers
  );
  const selectedDeliveryNumbers = useSelector(
    (state) => state.markerCoordinates.selectedDeliveryNumbers
  );

  const user = useSelector((state) => state.user);

  const [showFilters, setShowFilters] = useState({
    status: false,
    size: false,
    deliveryNumber: false,
  });

  const dispatch = useDispatch();

  const handleChange = (evt) => {
    console.log(evt.target.value);
    dispatch(fetchSiteidSuccess(evt.target.value));
  };

  const handleZoneClick = (zone) => {
    const tempObj = {};
    tempObj[zone] = true;

    if (zones.selected[zone] === null || zones.selected[zone] === true) {
      tempObj[zone] = false;
    }

    dispatch(selectZones(tempObj));
  };

  const handleStatusCheckboxClick = (checked, statusType) => {
    const statusSelectObj = {};
    statusSelectObj[statusType] = !checked;

    dispatch(selectSiteStatus(statusSelectObj));
  };

  const handleSiteSizeCheckboxClick = (checked, size) => {
    const sizeSelectObj = {};
    sizeSelectObj[size] = !checked;

    dispatch(selectSiteSize(sizeSelectObj));
  };

  const handleDeliveryNumberCheckboxClick = (checked, deliveryNumber) => {
    const deliveryNumberSelectObj = {};
    deliveryNumberSelectObj[deliveryNumber] = !checked;

    dispatch(selectDeliveryNumber(deliveryNumberSelectObj));
  };

  const toggleDropdown = (dropdown) => {
    setShowFilters({
      ...showFilters,
      status: false,
      size: false,
      deliveryNumber: false,
      [dropdown]: !showFilters[dropdown],
    });
  };

  const dropdownRefs = useRef([]);

  const handleCloseDropdown = (dropdown, index) => {
    return (e) => {
      if (dropdownRefs.current[index].contains(e.relatedTarget)) {
        return;
      }

      setShowFilters({
        ...showFilters,
        [dropdown]: false,
      });
    };
  };

  return (
    <div className="topbar-container">
      <div className="topbar-sub-container topbar-sub-container-left">
        <Box className="box-container box-search">
          <Icon
            title="Search"
            baseClassName="fas"
            className="fa-regular fa-magnifying-glass container-icon"
            fontSize="small"
          />
          <TextField
            id="input-with-sx"
            placeholder={
              user.language !== "hi"
                ? "Search by Site ID"
                : "साइट आईडी से खोजें"
            }
            variant="standard"
            onChange={handleChange}
          />
        </Box>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("status", 0)}
          ref={(el) => (dropdownRefs.current[0] = el)} // Set a ref to the first dropdown
          tabIndex={11}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("status")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "11rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Status" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.status && (
            <div className="status-select-dropdown">
              {siteStatus.data.map((statusObj) => {
                return (
                  <div
                    key={"status-"+statusObj.value}
                    className="site-info-status-select-sub-container cursor-pointer"
                    style={{ color: statusObj.color }}
                    onClick={() => {
                      handleStatusCheckboxClick(
                        siteStatus.selected[statusObj.value],
                        statusObj.value
                      );
                    }}
                  >
                    <Checkbox
                      onChange={(evt) =>
                        handleStatusCheckboxClick(
                          siteStatus.selected[statusObj.value],
                          statusObj.value
                        )
                      }
                      checked={siteStatus.selected[statusObj.value] === true}
                    />

                    {statusObj.type === "image" ? (
                      <img
                        src={pmayLogo}
                        alt={statusObj.label}
                        style={{
                          width: "3rem",
                          height: "1.5rem",
                        }}
                      />
                    ) : (
                      <Icon
                        title={statusObj.label}
                        baseClassName={statusObj.iconBaseClass || "fas"}
                        className={"cursor-pointer " + statusObj.icon}
                        fontSize="small"
                      />
                    )}

                    <p className="m-0" style={{ flexGrow: 1 }}>
                      <Translate text={statusObj.label} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("size", 1)}
          ref={(el) => (dropdownRefs.current[1] = el)} // Set a ref to the second dropdown
          tabIndex={12}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("size")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "10rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Size" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.size && (
            <div className="status-select-dropdown">
              {sizes.map((size) => {
                return (
                  <div
                    key={"size-"+size}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handleSiteSizeCheckboxClick(selectedSizes[size], size);
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handleSiteSizeCheckboxClick(selectedSizes[size], size)
                      }
                      checked={selectedSizes[size] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1 }}>
                      <Translate text={size} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("deliveryNumber", 2)}
          ref={(el) => (dropdownRefs.current[2] = el)} // Set a ref to the second dropdown
          tabIndex={13}
          translate="yes"
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("deliveryNumber")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "15rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Delivery Number" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.deliveryNumber && (
            <div
              className="status-select-dropdown"
              style={{
                maxHeight: "10rem",
                overflowY: "scroll",
                width: "15rem",
              }}
            >
              {deliveryNumbers.map((deliveryNumber) => {
                return (
                  <div
                    key={"deliveryNumber-"+deliveryNumber}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handleDeliveryNumberCheckboxClick(
                        selectedDeliveryNumbers[deliveryNumber],
                        deliveryNumber
                      );
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handleDeliveryNumberCheckboxClick(
                          selectedDeliveryNumbers[deliveryNumber],
                          deliveryNumber
                        )
                      }
                      checked={selectedDeliveryNumbers[deliveryNumber] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1 }}>
                      <Translate text={deliveryNumber || "-- Not Delivered --"} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {zones.data.map((zone) => (
          <Button
            key={"zone-"+zone.Zone}
            variant="contained"
            onClick={() => handleZoneClick(zone.Zone)}
            style={{
              background: zone.color,
              border: "1px solid var(--border-color)",
              fontWeight: "bold",
              borderRadius: "8px",
            }}
          >
            {zones.selected[zone.Zone] === true && (
              <Icon
                title="Selected"
                baseClassName="fas"
                className="fa-regular fa-check container-icon"
                fontSize="small"
              />
            )}
            <Translate text={zone.Zone} />
          </Button>
        ))}
      </div>
    </div>
  );
}

export default MapTopbar;
